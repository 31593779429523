import React from "react"

export default function({ name, address, phone, hours }) {
  return (
    <div class="pantry">
      <div class="pantry-name">{name}</div>
      <div
        class="pantry-address"
        dangerouslySetInnerHTML={{ __html: address }}
      />
      <div class="pantry-phone">{phone}</div>
      <div class="pantry-hours" dangerouslySetInnerHTML={{ __html: hours }} />
    </div>
  )
}
