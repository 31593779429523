import React from "react"

const Wysiwyg = ({ content, backgroundColor }) => (
  <div className="block-wysiwyg">
    <div className="container">
      <div className={`contentContainer ${backgroundColor}`}>
        <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  </div>
)

export default Wysiwyg
