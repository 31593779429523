import React from "react"

const Recipient = ({ name, school, image }) => (
  <div className="scholarship-recipient">
    <div className="scholarship-recipient-name">{name}</div>
    <div className="scholarship-recipient-school">{school}</div>
    {image && <img src={image} alt={name} />}
  </div>
)

const Scholarship = ({ scholarship, recipients }) => {
  const recips = [];
  if (recipients) {
    recipients.forEach((recipient, index) => recips.push(<Recipient {...recipient} key={index} />))
  }
  return (
    <div className="block-wysiwyg">
      <div className="container">
        <div className="contentContainer White">
          <div className="content">
            <section className="scholarship-section">
              <h3>{scholarship}</h3>
              <div className="scholarship-recipients">
                {recips}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Scholarship
