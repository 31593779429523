import React from "react"

const FeaturedImage = ({ featuredImage, imageSize, url }) => {
  const containerClass = imageSize === "Full" ? "full" : "container"

  const El = url ? 'a' : 'div';

  return (
    <div className="featuredImage">
      <div className={containerClass}>
        <El href={ url || '#' }>
          <img width="100%" src={ featuredImage } alt="" />
        </El>
      </div>
    </div>
  )
}

// FeaturedImage.propTypes = {
//   featuredImage: PropTypes.object,
//   imageSize: PropTypes.string,
//   url: PropTypes.string,
// }

export default FeaturedImage
