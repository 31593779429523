import React from "react"
import camelCase from "camelcase"
import Layout from "../components/layout"
import Title from "../components/blocks/Title"
import FeaturedImage from "../components/blocks/FeaturedImage"
import Wysiwyg from "../components/blocks/Wysiwyg"
import Event from "../components/blocks/EventListing"
import Scholarship from "../components/Scholarship"
import Pantry from "../components/Pantry"
import SupportersGrid from "../components/SupportersGrid"
import { Helmet } from 'react-helmet'

const blocksAvailable = {
  Title,
  FeaturedImage,
  Wysiwyg,
  SupportersGrid,
  Event,
}

const handleScholarships = scholarships => {
  return scholarships.map(s => <Scholarship {...s} />)
}

const handleDonationGroups = donationGroups => {
  return (
    <section className="block-wysiwyg">
      <div className="container">
        <div className="contentContainer White">
          <div className="content">
          <p>
            As a section 501(c)(3) not-for-profit organization, your donation is tax deductible as allowed by federal law.
          </p>
          </div>
          <div className="content">
            {donationGroups.map((dg, index) => {
              return (
                <div className="content">
                  <hr />
                  <h3>{dg.title}</h3>
                  <p>{dg.description}</p>
                  <p dangerouslySetInnerHTML={{ __html: dg.paypal_html }} />
                  {index !== donationGroups.length - 1 ? (
                    <div>
                      <p>
                        <a href="https://cash.app/$UnityFdn">
                          Cash.app - $UnityFdn
                        </a>
                      </p>
                      <p>
                        <a href="https://venmo.com/unityfdn">
                          Venmo - @UnityFdn
                        </a>
                      </p>
                    </div>
                  ) : null}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

const handlePantries = pantries => {
  return (
    <section>
      <div class="container">
        <div class="bg-white">
          <div class="pantries">
            {pantries.map(p => (
              <Pantry {...p} />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

const handleFlexibleContent = content => {
  const blocks = content.map(block => {
    // Map the props to camelCase
    const props = []
    for (var prop in block) {
      if (prop !== "__typename") {
        props[camelCase(prop)] = block[prop]
      }
    }

    // Get the component name in PascalCase
    const componentName = camelCase(block.acf_fc_layout, {
      pascalCase: true,
    })

    // Create the component
    const Component = blocksAvailable[componentName]

    // console.log(component)
    return <Component {...props} />
  })
  return blocks
}

export default ({ pageContext: { wpPage }, location }) => {
  const blocks = []
  const flexibleContent = wpPage.acf.content
  const scholarships = wpPage.acf.scholarships
  const pantries = wpPage.acf.pantries
  const donationGroups = wpPage.acf.donation_group

  if (flexibleContent) {
    blocks.push(handleFlexibleContent(flexibleContent))
  }

  if (scholarships) {
    blocks.push(handleScholarships(scholarships))
  }

  if (pantries) {
    blocks.push(handlePantries(pantries))
  }

  if (donationGroups) {
    blocks.push(handleDonationGroups(donationGroups))
  }

  return <Layout location={location}>
  <Helmet>
    <title>The Unity in Community Foundation</title>
    <meta name="description" content="We serve the people who reside within the borders of Leyden Township: Elmwood Park, Franklin Park, Northlake, River Grove, Rosemont, Schiller Park, and Unincorporated Leyden Township. “Putting UNITY back in CommUNITY” is our purpose."/>
  </Helmet>{blocks}</Layout>
}
