import React, { useState } from "react"
import { Link } from "gatsby"
import axios from "axios"

export default ({ event }) => {
  const [featuredEvent, setFeaturedEvent] = useState()

  if (!featuredEvent) {
    axios
      .get(
        `https://mason.antlur.co/unityincommunity/wp-json/wp/v2/events/${event.ID}`
      )
      .then(res => {
        setFeaturedEvent(res.data)
      })
    return <div />
  }

  const [
    featuredEventDay,
    featuredEventMonth,
    featuredEventYear,
  ] = featuredEvent.acf.date.split("/")

  const featuredEventDate = new Date(
    featuredEventYear,
    featuredEventMonth - 1,
    featuredEventDay
  ).toLocaleString("en-us", { month: "long", day: "2-digit", year: "numeric" })

  const ticketLink = featuredEvent.acf.ticket_url ? (
    <a
      className="button"
      style={{ marginBottom: "1rem" }}
      href={featuredEvent.acf.ticket_url}
      target="_blank"
      rel="noopener noreferrer"
    >
      Buy Tickets
    </a>
  ) : null
  return (
    <div className="container">
      <div className="featuredEvent">
        <div className="featuredEvent__info">
          <div className="featuredEvent__date">{featuredEventDate}</div>
          <div
            className="featuredEvent__title"
            dangerouslySetInnerHTML={{ __html: featuredEvent.title.rendered }}
          ></div>
          <div className="featuredEvent__time">
            {featuredEvent.acf.start_time}
          </div>
          <div
            className="featuredEvent__description"
            dangerouslySetInnerHTML={{
              __html: featuredEvent.acf.description.substr(0, 210) + "...",
            }}
          />
          <Link
            to={`/events/${featuredEvent.slug}`}
            className="button-green mr-2"
          >
            Event Info
          </Link>
          {ticketLink}
        </div>
        <div className="featuredEvent__image">
          <Link to={`/events/${featuredEvent.slug}`}>
            <img src={featuredEvent.acf.image} alt="" />
          </Link>
        </div>
      </div>
    </div>
  )
}
