import React from "react"

export default function({supporters}) {
  return (
    <div class="block-supportersGrid">
      <div class="container">
        <div class="contentContainer">
          <div class="content">
            <div class="block-supportersGrid-supporters">
              {supporters.map(supporter => {
                return (
                  <div class="block-supportersGrid-supporter">
                    <div>
                      <img src={supporter.logo} alt="" />
                    </div>
                    <div>{supporter.name}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
